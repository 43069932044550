import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// export const firebaseConfig = {
//   apiKey: process.env.REACT_APP_apiKey,
//   authDomain: process.env.REACT_APP_authDomain,
//   projectId: process.env.REACT_APP_projectId,
//   storageBucket: process.env.REACT_APP_storageBucket,
//   messagingSenderId: process.env.REACT_APP_messagingSenderId,
//   appId: process.env.REACT_APP_appId,
//   measurementId: process.env.REACT_APP_measurementId,
// };
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKeyTest,
  authDomain: process.env.REACT_APP_authDomainTest,
  projectId: process.env.REACT_APP_projectIdTest,
  storageBucket: process.env.REACT_APP_storageBucketTest,
  messagingSenderId: process.env.REACT_APP_messagingSenderIdTest,
  appId: process.env.REACT_APP_appIdTest,
  // measurementId: process.env.REACT_APP_measurementId,
};

const app = initializeApp(firebaseConfig);

// export const db = getFirestore(app);

export const auth = getAuth(app);
